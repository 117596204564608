import PhoneImage from "../images/phone.png";
import ReportAPIService from "../api/reports.api.js";
import FormatNumbersFunctions from "./FormatNumbersFunctions.js";
import html2canvas from "html2canvas";
// import jsPDF from "jspdf";

class HelperFunctions {
  async generateImages(companyName) {
    // Select the elements you want to capture
    const components = document.querySelectorAll(".component-to-capture");

    for (let index = 0; index < components.length; index++) {
      const component = components[index];

      // Capture the component as an image using html2canvas
      const canvas = await html2canvas(component);

      // Convert the canvas to a data URL (image)
      const imgData = canvas.toDataURL("image/png");

      // Create a link element to save the component image as a file
      const link = document.createElement("a");
      link.href = imgData;
      link.download = `${companyName}_${index + 1}.png`;
      link.style.display = "none"; // Hide the link

      // Trigger a click event on the link to save the component image
      link.click();
    }
  }

  async generateIframe(companyName, previewHtml) {
    // Create the elements
    const previewHtmlDiv = document.createElement("div");
    previewHtmlDiv.className = "previewHtml";
    previewHtmlDiv.style.width = "490px";
    previewHtmlDiv.style.height = "755px";

    const phoneImage = document.createElement("img");
    phoneImage.setAttribute("ref", "phoneImageRef");
    phoneImage.setAttribute("src", PhoneImage);
    phoneImage.setAttribute("alt", "Phone");
    phoneImage.style.height = "100%";
    phoneImage.setAttribute("width", "380");
    phoneImage.style.position = "absolute";
    phoneImage.style.top = "0";
    phoneImage.style.right = "0";

    const campaignPreviewDiv = document.createElement("div");
    campaignPreviewDiv.className = "campaign-preview";
    campaignPreviewDiv.id = "campaign-preview";
    campaignPreviewDiv.style.width = "330px";
    campaignPreviewDiv.style.height = "720px";
    campaignPreviewDiv.style.marginLeft = "135px";
    campaignPreviewDiv.style.marginTop = "15px";

    const previewIframe = document.createElement("iframe");
    previewIframe.setAttribute("ref", "previewRefPage1");
    previewIframe.setAttribute("title", "campaign-preview");
    previewIframe.setAttribute("srcdoc", previewHtml);
    previewIframe.setAttribute("scrolling", "no");
    previewIframe.setAttribute("width", "330");
    previewIframe.setAttribute("height", "720");

    // Append elements to respective parents
    campaignPreviewDiv.appendChild(previewIframe);
    previewHtmlDiv.appendChild(phoneImage);
    previewHtmlDiv.appendChild(campaignPreviewDiv);

    // Append the main div to the document body
    document.body.appendChild(previewHtmlDiv);

    // Use html2canvas to capture the rendered HTML to an image
    const canvas = await html2canvas(previewHtmlDiv, {
      backgroundColor: "transparent",
    });

    // Convert the canvas to a data URL (image)
    const imgData = canvas.toDataURL("image/png");

    // Create a link element to save the component image as a file
    const link = document.createElement("a");
    link.href = imgData;
    link.download = `${companyName}_iframe.png`;
    link.style.display = "none"; // Hide the link

    // Trigger a click event on the link to save the component image
    link.click();

    document.body.removeChild(previewHtmlDiv);
  }

  // async generatePDF() {
  //   const pdf = new jsPDF("l", "mm", "a4");

  //   // Select the elements you want to capture
  //   const components = document.querySelectorAll(".component-to-capture");

  //   for (let index = 0; index < components.length; index++) {
  //     if (index > 0) {
  //       pdf.addPage();
  //     }

  //     const component = components[index];

  //     // Capture the component as an image using html2canvas
  //     const canvas = await html2canvas(component);

  //     const imgData = canvas.toDataURL("image/png");

  //     // Calculate the width and height based on landscape orientation
  //     const pdfWidth = pdf.internal.pageSize.getWidth();
  //     const pdfHeight = pdf.internal.pageSize.getHeight();

  //     // Calculate the aspect ratio of the image
  //     const imgRatio = canvas.width / canvas.height;

  //     let imgWidth = pdfWidth;
  //     let imgHeight = pdfWidth / imgRatio;

  //     // Check if the image height exceeds the page height, if so, scale it down
  //     if (imgHeight > pdfHeight) {
  //       imgHeight = pdfHeight;
  //       imgWidth = pdfHeight * imgRatio;
  //     }

  //     // Calculate the center position for the image
  //     const x = (pdfWidth - imgWidth) / 2;
  //     const y = (pdfHeight - imgHeight) / 2;

  //     // Add the captured component image to the PDF
  //     pdf.addImage(imgData, "PNG", x, y, imgWidth, imgHeight);
  //   }
  //   // Save or display the PDF
  //   pdf.save(`${companyName}_${sendTime}.pdf`);
  // }
  async LoadNewData(companyName, campaignId) {
    const [errorUpdatedCampaign, updatedCampaign] =
      await ReportAPIService.GetCampaignWithId(companyName, campaignId);
    if (errorUpdatedCampaign) {
      console.error("Error fetching campaigns:", errorUpdatedCampaign);
    }

    const [errorUpdatedReport, updatedReport] =
      await ReportAPIService.GetReportWithId(companyName, campaignId);
    if (errorUpdatedReport) {
      console.error("Error fetching report:", errorUpdatedReport);
    }
    const [errorUpdatedLinks, updatedLinks] =
      await ReportAPIService.GetLinksWithId(companyName, campaignId);
    if (errorUpdatedLinks) {
      console.error("Error fetching links:", errorUpdatedLinks);
    }
    const [errorUpdatedPreview, updatedPreview] =
      await ReportAPIService.GetPreviewWithId(companyName, campaignId);
    if (errorUpdatedPreview) {
      console.error("Error fetching preview:", errorUpdatedPreview);
    }

    return [updatedCampaign, updatedReport, updatedLinks, updatedPreview];
  }

  async LoadNewDataNoCampaign(companyName, campaignId) {
    const [errorReport, report] = await ReportAPIService.GetReportWithId(
      companyName,
      campaignId
    );
    if (errorReport) {
      console.error("Error fetching report:", errorReport);
    }
    const [errorLinks, links] = await ReportAPIService.GetLinksWithId(
      companyName,
      campaignId
    );
    if (errorLinks) {
      console.error("Error fetching links:", errorLinks);
    }
    const [errorPreview, preview] = await ReportAPIService.GetPreviewWithId(
      companyName,
      campaignId
    );
    if (errorPreview) {
      console.error("Error fetching preview:", errorPreview);
    }

    return [report, links, preview];
  }

  getBrokerNameForFileName(campaign) {
    let brokerName = campaign.settings.title.split(" - ");
    brokerName = brokerName[0].replace(/ /g, "-");
    return brokerName;
  }

  getBrokerNameWithSpace(campaign) {
    let brokerName = campaign.settings.title.split(" - ");
    brokerName = brokerName[0];
    return brokerName;
  }

  async fetchLiveData(companyName, campaigns) {
    let numberOfSavedCampaigns = 0;
    let finalIndex = -1;
    const [errorCampaignsList, campaignsList] =
      await ReportAPIService.GetCampaigns(companyName);
    if (errorCampaignsList) {
      console.error("Error fetching campaigns:", errorCampaignsList);
    }
    if (campaignsList) {
      for (let i = 0; i < campaignsList.length; i++) {
        let exist = campaigns.some((item) => item.id === campaignsList[i].id);
        if (!exist) {
          console.log(campaignsList[i].sendTime, campaignsList[i].id);
          let [report, links, preview] = await this.LoadNewDataNoCampaign(
            companyName,
            campaignsList[i].id
          );
          let data = [campaignsList[i], report, links, preview];
          let url = "";
          if (companyName.includes("workshop-7")) {
            url = `https://hitsendreporting.s05.system7.co.nz/api/api/report/save/${companyName}/${FormatNumbersFunctions.formatDateWithTime(
              new Date(campaignsList[i].sendTime)
            )}`;
          } else if (companyName.includes("cloudbase")) {
            url = `https://hitsendreporting.s05.system7.co.nz/api/api/report/save/${companyName}/${FormatNumbersFunctions.formatDateWithId(
              new Date(campaignsList[i].sendTime),
              campaignsList[i].id
            )}`;
          } else if (
            companyName.includes("crediflex") ||
            companyName.includes("mtp")
          ) {
            let brokerName = this.getBrokerNameForFileName(campaignsList[i]);
            url = `https://hitsendreporting.s05.system7.co.nz/api/api/report/save/${companyName}/${brokerName}/${FormatNumbersFunctions.formatDateForFileName(
              new Date(campaignsList[i].sendTime)
            )}`;
          } else {
            url = `https://hitsendreporting.s05.system7.co.nz/api/api/report/save/${companyName}/${FormatNumbersFunctions.formatDateForFileName(
              new Date(campaignsList[i].sendTime)
            )}`;
          }
          const [error, result] = await ReportAPIService.SaveData(url, data);
          if (error) {
            console.error("Error fetching preview:", error);
          }
          if (result) {
            numberOfSavedCampaigns++;
          }
        } else {
          finalIndex = i;
          break;
        }
      }
      if (finalIndex === numberOfSavedCampaigns) {
        window.location.reload();
      }
    }
  }

  getColour(companyName, pageNum, maxPageNumber) {
    let gradientColour1 = "";
    let gradientColour2 = "";
    let pageName = "";
    //choose the colour based on the company name
    if (!companyName.includes("cloudbase")) {
      if (companyName === "system7") {
        gradientColour1 = "#FCDFD0";
        gradientColour2 = "#F26722";
      } else if (companyName === "bug-king") {
        gradientColour1 = "#C8E6ED";
        gradientColour2 = "#008AAB";
      } else if (companyName === "vita-pacific") {
        gradientColour1 = "#FFF7C9";
        gradientColour2 = "#FFDA00";
      } else if (companyName === "scafman") {
        gradientColour1 = "#CCECFC";
        gradientColour2 = "#22ABF3";
      } else if (companyName === "david-kerr") {
        gradientColour1 = "#cecece";
        gradientColour2 = "#292929";
      } else if (companyName === "angel-property-managers") {
        gradientColour1 = "#D3EEF3";
        gradientColour2 = "#32B1CA";
      } else if (companyName === "greenside-energy-solutions") {
        gradientColour1 = "#DCEBD4";
        gradientColour2 = "#5FA138";
      } else if (companyName === "metalcraft-insulated-panels") {
        gradientColour1 = "#D0D4D7";
        gradientColour2 = "#233747";
      } else if (companyName === "workshop-7") {
        gradientColour1 = "#FFF7D1";
        gradientColour2 = "#091E35";
      } else if (companyName === "leck-electrical") {
        gradientColour1 = "#EDF5CF";
        gradientColour2 = "#B1D238";
      } else if (companyName === "mat-wood") {
        gradientColour1 = "#E8D4E7";
        gradientColour2 = "#94368F";
      } else if (companyName === "crediflex") {
        gradientColour1 = "#E8D4E7";
        gradientColour2 = "#94368F";
      } else if (companyName === "mtp") {
        gradientColour1 = "#FFEDE5";
        gradientColour2 = "#FF4D00";
      } else if (companyName === "contact-lens-express") {
        gradientColour1 = "#D3F2FC";
        gradientColour2 = "#45C7F4";
      }
      for (let i = 1; i <= maxPageNumber; i++) {
        if (companyName === "crediflex" || companyName === "mtp") {
          pageName = `.monthly-page-${i}-crediflex`;
        } else {
          pageName = `.monthly-page-${i}`;
        }
        const pageBackground = document.querySelector(pageName);
        if (pageBackground) {
          pageBackground.style.setProperty(
            "--gradient-color1",
            gradientColour1
          );
        }
      }
      const circleBackground = document.querySelectorAll(`.circle`);
      const secondaryNumberColour =
        document.querySelectorAll(`.secondary-number`);
      const previousMonthTextColour =
        document.querySelectorAll(`.legend-symbol-2`);
      const dotColour = document.querySelectorAll(`.chart-dot`);
      const lineColour = document.querySelectorAll(`.chart-line`);
      const labelColour = document.querySelectorAll(`.chart-label`);
      if (circleBackground)
        circleBackground.forEach((element) => {
          element.style.setProperty("--gradient-color2", gradientColour2);
        });
      if (secondaryNumberColour)
        secondaryNumberColour.forEach((element) => {
          element.style.setProperty("--gradient-color2", gradientColour2);
        });
      if (previousMonthTextColour)
        previousMonthTextColour.forEach((element) => {
          element.style.setProperty("--gradient-color2", gradientColour2);
        });
      if (dotColour)
        dotColour.forEach((element) => {
          element.style.setProperty("--gradient-color2", gradientColour2);
        });
      if (lineColour)
        lineColour.forEach((element) => {
          element.style.setProperty("--gradient-color2", gradientColour2);
        });
      if (labelColour)
        labelColour.forEach((element) => {
          element.style.setProperty("--gradient-color2", gradientColour2);
        });
    } else {
      // Get the element with the gradient background
      const circleBackground = document.querySelectorAll(`.circle`);
      const secondaryNumberColour =
        document.querySelectorAll(`.secondary-number`);
      const previousMonthTextColour =
        document.querySelectorAll(`.legend-symbol-2`);
      const dotColour = document.querySelectorAll(`.chart-dot`);
      const lineColour = document.querySelectorAll(`.chart-line`);
      const labelColour = document.querySelectorAll(`.chart-label`);
      for (let i = 1; i <= 8; i++) {
        const pageBackground = document.querySelector(
          `.monthly-page-${i}-cloudbase`
        );
        if (i / 5 < 1) {
          if (pageBackground)
            pageBackground.style.setProperty("--gradient-color1", "#CAD4E5");
        } else {
          if (pageBackground)
            pageBackground.style.setProperty("--gradient-color1", "#960b5c30");
        }
      }
      if (circleBackground)
        circleBackground.forEach((element, index) => {
          if (pageNum < 5) {
            if (index === 0)
              element.style.setProperty("--gradient-color2", "#0A3785");
            else if (index === 1)
              element.style.setProperty("--gradient-color2", "#960b5c");
          } else element.style.setProperty("--gradient-color2", "#960b5c");
        });
      if (secondaryNumberColour)
        secondaryNumberColour.forEach((element) => {
          if (pageNum < 5)
            element.style.setProperty("--gradient-color2", "#0A3785");
          else element.style.setProperty("--gradient-color2", "#960b5c");
        });
      if (previousMonthTextColour)
        previousMonthTextColour.forEach((element) => {
          if (pageNum < 5)
            element.style.setProperty("--gradient-color2", "#0A3785");
          else element.style.setProperty("--gradient-color2", "#960b5c");
        });

      if (dotColour)
        dotColour.forEach((element) => {
          element.style.setProperty("--gradient-color2", "#0A3785");
        });
      if (lineColour)
        lineColour.forEach((element) => {
          element.style.setProperty("--gradient-color2", "#0A3785");
        });
      if (labelColour)
        labelColour.forEach((element) => {
          element.style.setProperty("--gradient-color2", "#0A3785");
        });
      const page6CloudbaseBackground = document.querySelector(
        `.monthly-page-6-cloudbase`
      );
      if (page6CloudbaseBackground) {
        const secondaryNumberColourCloudbase =
          page6CloudbaseBackground.querySelectorAll(`.secondary-number`);
        const previousMonthTextColourCloudbase =
          page6CloudbaseBackground.querySelectorAll(`.legend-symbol-2`);
        if (secondaryNumberColourCloudbase)
          secondaryNumberColourCloudbase.forEach((element) => {
            element.style.setProperty("--gradient-color2", "#960b5c");
          });
        if (previousMonthTextColourCloudbase)
          previousMonthTextColourCloudbase.forEach((element) => {
            element.style.setProperty("--gradient-color2", "#960b5c");
          });
      }
      const page7CloudbaseBackground = document.querySelector(
        `.monthly-page-7-cloudbase`
      );
      if (page7CloudbaseBackground) {
        const dotColour =
          page7CloudbaseBackground.querySelectorAll(`.chart-dot`);
        const lineColour =
          page7CloudbaseBackground.querySelectorAll(`.chart-line`);
        const labelColour =
          page7CloudbaseBackground.querySelectorAll(`.chart-label`);
        const previousMonthTextColourCloudbase =
          page7CloudbaseBackground.querySelectorAll(`.legend-symbol-2`);
        if (previousMonthTextColourCloudbase)
          previousMonthTextColourCloudbase.forEach((element) => {
            element.style.setProperty("--gradient-color2", "#960b5c");
          });
        if (dotColour)
          dotColour.forEach((element) => {
            element.style.setProperty("--gradient-color2", "#960b5c");
          });
        if (lineColour)
          lineColour.forEach((element) => {
            element.style.setProperty("--gradient-color2", "#960b5c");
          });
        if (labelColour)
          labelColour.forEach((element) => {
            element.style.setProperty("--gradient-color2", "#960b5c");
          });
      }
      const page8CloudbaseBackground = document.querySelector(
        `.monthly-page-8-cloudbase`
      );
      if (page8CloudbaseBackground) {
        const dotColour =
          page8CloudbaseBackground.querySelectorAll(`.chart-dot`);
        const lineColour =
          page8CloudbaseBackground.querySelectorAll(`.chart-line`);
        const labelColour =
          page8CloudbaseBackground.querySelectorAll(`.chart-label`);
        const previousMonthTextColourCloudbase =
          page8CloudbaseBackground.querySelectorAll(`.legend-symbol-2`);
        if (previousMonthTextColourCloudbase)
          previousMonthTextColourCloudbase.forEach((element) => {
            element.style.setProperty("--gradient-color2", "#960b5c");
          });
        if (dotColour)
          dotColour.forEach((element) => {
            element.style.setProperty("--gradient-color2", "#960b5c");
          });
        if (lineColour)
          lineColour.forEach((element) => {
            element.style.setProperty("--gradient-color2", "#960b5c");
          });
        if (labelColour)
          labelColour.forEach((element) => {
            element.style.setProperty("--gradient-color2", "#960b5c");
          });
      }
    }
  }
}
const helperFunctions = new HelperFunctions();
export default helperFunctions;
