import React, { useState, useEffect, useRef } from "react";
import MonthlyPage1Crediflex from "./MonthlyPage1Crediflex.js";
import MonthlyPage2Crediflex from "./MonthlyPage2Crediflex.js";
import ReportAPIService from "../api/reports.api.js";
import HelperFunctions from "../HelperFunctions/HelperFunctions.js";
import FormatNumbersFunctions from "../HelperFunctions/FormatNumbersFunctions.js";

export default function MonthlyReportCrediflex() {
  const urlList = window.location.href.split("//").pop().split("/");
  const companyName = urlList[1];
  const sendTime = urlList[2];
  const day = sendTime.split("-")[2];
  const month = sendTime.split("-")[1];
  const year = sendTime.split("-")[0];
  const fileName = `${companyName}_${sendTime}`;
  const monthlyDate = new Date();
  const [pageNum, setPageNum] = useState(1);
  const [campaigns, setCampaigns] = useState([]);
  const [reports, setReports] = useState();
  const [links, setLinks] = useState();
  const [previewHtml, setPreviewHtml] = useState("");
  const [topLinks, setTopLinks] = useState([]); //{ url: "", clicks: 0 }
  const [codeRun, setCodeRun] = useState(0);
  const [isUpdateClicked, setIsUpdateClicked] = useState(false);

  const previewRefPage1 = useRef(null);
  const phoneImageRef = useRef(null);

  const [savedCampaigns, setSavedCampaigns] = useState([]);
  const [savedReports, setSavedReports] = useState([]);

  useEffect(() => {
    async function getContentFromSavedFile(savedFileNames) {
      let tempSavedCampaigns = [];
      let tempSavedReports = [];
      let tempCampaigns = [];
      let tempReports = [];
      let tempLinksList = [];
      let preview = "";

      const thisDate = new Date(sendTime);

      let revFileNames = savedFileNames.reverse();

      let previousMonth = -1;
      let previousYear = -1;
      for (const element of revFileNames) {
        let tempLinks = [];
        let campaignDate = new Date(element.split("_")[0]);
        if (
          campaignDate.getDate() === parseInt(day) &&
          campaignDate.getMonth() + 1 === parseInt(month) &&
          campaignDate.getFullYear() === parseInt(year)
        ) {
          const [errorReturnedData, returnedData] =
            await ReportAPIService.GetSavedFileContent(companyName, element);
          if (errorReturnedData) {
            console.error("Error fetching campaigns:", errorReturnedData);
          }
          if (
            returnedData[0] &&
            returnedData[1] &&
            returnedData[2] &&
            returnedData[3]
          ) {
            tempCampaigns = tempCampaigns.concat(returnedData[0]);
            tempReports = tempReports.concat(returnedData[1]);
            tempLinks = tempLinks.concat(returnedData[2]);
            if (preview === "") {
              preview = returnedData[3];
            }
          }
          tempLinksList.push(tempLinks);
        }
        if (
          parseInt(month) !== campaignDate.getMonth() + 1 &&
          (previousMonth === -1 ||
            previousMonth === campaignDate.getMonth() + 1) &&
          (previousYear === -1 ||
            previousYear === campaignDate.getFullYear()) &&
          campaignDate < thisDate
        ) {
          previousMonth = campaignDate.getMonth() + 1;
          previousYear = campaignDate.getFullYear();
          const [errorReturnedData, returnedData] =
            await ReportAPIService.GetSavedFileContent(companyName, element);
          if (errorReturnedData) {
            console.error("Error fetching campaigns:", errorReturnedData);
          }
          if (
            returnedData[0] &&
            returnedData[1] &&
            returnedData[2] &&
            returnedData[3]
          ) {
            tempSavedCampaigns = tempSavedCampaigns.concat(returnedData[0]);
            tempSavedReports = tempSavedReports.concat(returnedData[1]);
          }
        }
      }
      setSavedCampaigns(tempSavedCampaigns);
      setSavedReports(tempSavedReports);

      let matWoodsCampaign = tempCampaigns.filter((element) => {
        return element.settings.title.includes("Mat");
      });
      if (matWoodsCampaign.length > 0) {
        tempCampaigns = tempCampaigns.filter((element) => {
          return !matWoodsCampaign.some(
            (matElement) => matElement.id === element.id
          );
        });
        tempReports = tempReports.filter((element) => {
          return !matWoodsCampaign.some(
            (matElement) => matElement.id === element.id
          );
        });
        tempLinksList = tempLinksList.filter((element) => {
          return !matWoodsCampaign.some(
            (matElement) => matElement.id === element[0].campaignId
          );
        });
      }

      console.log(tempCampaigns);
      setCampaigns(tempCampaigns);
      setReports(tempReports);
      setLinks(tempLinksList);
      setPreviewHtml(preview);
    }

    async function getSavedFiles() {
      const [errorReturnedData, returnedData] =
        await ReportAPIService.GetSavedFileNames(companyName);
      if (errorReturnedData) {
        console.error("Error fetching campaigns:", errorReturnedData);
      }
      if (returnedData) {
        returnedData.forEach((element, index, array) => {
          array[index] = element.split("\\").pop();
        });
      }
      return returnedData;
    }

    async function loadSavedData() {
      const returnedData = await getSavedFiles();
      if (
        campaigns === null ||
        campaigns === undefined ||
        campaigns.length === 0
      ) {
        await getContentFromSavedFile(returnedData);
      }
    }

    loadSavedData();

    return () => {};
  }, [
    campaigns,
    companyName,
    day,
    fileName,
    isUpdateClicked,
    links,
    month,
    pageNum,
    previewHtml,
    reports,
    sendTime,
    year,
  ]);

  useEffect(() => {
    HelperFunctions.getColour(companyName, pageNum, 11);
  });

  function nextClicked() {
    setPageNum(pageNum + 1);
  }

  function prevClicked() {
    setPageNum(pageNum - 1);
  }

  function backClicked() {
    window.location.href = `/${companyName}/reports-list`;
  }

  function combineClicksOfSameLinks(links) {
    var linkListPerCampaign = []; //{ url: "", clicks: 0 }

    links.forEach((link) => {
      var linkList = []; //{ url: "", clicks: 0 }
      link.forEach((firstLink) => {
        let linkURL = firstLink.url.split("?")[0];
        let totalClicks = 0;
        link.forEach((secondLink) => {
          let secondLinkURL = secondLink.url.split("?")[0];
          if (secondLinkURL === linkURL) {
            totalClicks += secondLink.totalClicks;
          }
        });
        linkList = linkList.concat({
          campaignId: firstLink.campaignId,
          url: firstLink.url,
          clicks: totalClicks,
        });
      });
      linkListPerCampaign = linkListPerCampaign.concat([linkList]);
      linkList = [];
    });
    reduceLinksList(linkListPerCampaign);
  }

  function reduceLinksList(linkListPerCampaign) {
    var uniqueListPerCampaign = [];
    linkListPerCampaign.forEach((linkList) => {
      var uniqueUrls = {};
      var uniqueList = [];

      linkList.sort(function (a, b) {
        return b.clicks - a.clicks;
      });

      linkList.forEach(function (item) {
        var url = item.url.split("?")[0];

        if (!uniqueUrls[url]) {
          uniqueList.push(item);
          uniqueUrls[url] = true;
        }
      });

      uniqueListPerCampaign = uniqueListPerCampaign.concat([uniqueList]);
    });

    setCodeRun(1);
    setTopLinks(uniqueListPerCampaign);
  }

  function getReportWithCampaign(campaignId) {
    for (let i = 0; i < reports.length; i++) {
      if (reports[i].id === campaignId) {
        return reports[i];
      }
    }
  }

  function getTopLinks(campaignId) {
    for (let i = 0; i < topLinks.length; i++) {
      if (topLinks[i][0].campaignId === campaignId) {
        return topLinks[i];
      }
    }
  }

  function getSavedCampaignWithCampaign(brokerName) {
    const nameList = brokerName.split(" ");
    const name = nameList[0];
    for (let i = 0; i < savedCampaigns.length; i++) {
      if (nameList[1] === "&" || nameList[1] === "and") {
        if (
          savedCampaigns[i].settings.title.includes(name + " &") ||
          savedCampaigns[i].settings.title.includes(name + " and")
        ) {
          return savedCampaigns[i];
        }
      } else {
        if (
          savedCampaigns[i].settings.title.includes(name + " " + nameList[1])
        ) {
          return savedCampaigns[i];
        }
      }
    }
  }

  function getSavedReportWithCampaign(brokerName) {
    const nameList = brokerName.split(" ");
    const name = nameList[0];
    for (let i = 0; i < savedReports.length; i++) {
      if (nameList[1] === "&" || nameList[1] === "and") {
        if (
          savedReports[i].campaignTitle.includes(name + " &") ||
          savedReports[i].campaignTitle.includes(name + " and")
        ) {
          return savedReports[i];
        }
      } else {
        if (savedReports[i].campaignTitle.includes(name + " " + nameList[1])) {
          return savedReports[i];
        }
      }
    }
  }

  async function updateData() {
    if (
      window.confirm(
        "Are you sure you want to update this report?\nThis will overwrite the saved data."
      )
    ) {
      setIsUpdateClicked(true);
      let numberOfSavedCampaigns = 0;
      campaigns.forEach(async (campaign) => {
        let [updatedCampaign, updatedReport, updatedLinks, updatedPreview] =
          await HelperFunctions.LoadNewData(companyName, campaign.id);
        let data = [
          updatedCampaign,
          updatedReport,
          updatedLinks,
          updatedPreview,
        ];
        let brokerName = HelperFunctions.getBrokerNameForFileName(campaign);
        let url = `https://hitsendreporting.s05.system7.co.nz/api/api/report/save/${companyName}/${brokerName}/${FormatNumbersFunctions.formatDateForFileName(
          new Date(campaign.sendTime)
        )}`;
        const [error, result] = await ReportAPIService.SaveData(url, data);
        if (error) {
          console.error("Error fetching preview:", error);
        }
        if (result) {
          numberOfSavedCampaigns++;
        }
        if (numberOfSavedCampaigns === campaigns.length) {
          setIsUpdateClicked(false);
          window.location.reload();
        }
      });
    }
  }

  function showPagesInPrintView(campaignIndex) {
    if (campaigns.length > campaignIndex) {
      return (
        <div
          key={`id-${campaignIndex}`}
          className="monthly-report"
          id="monthly-report"
        >
          <div
            className={`monthly-page-${
              campaignIndex + 2
            }-crediflex component-to-capture`}
            id="monthly-page-2-crediflex"
          >
            <MonthlyPage2Crediflex
              campaign={campaigns[campaignIndex]}
              report={getReportWithCampaign(campaigns[campaignIndex].id)}
              lastCampaign={getSavedCampaignWithCampaign(
                campaigns[campaignIndex].settings.title
              )}
              lastReport={getSavedReportWithCampaign(
                campaigns[campaignIndex].settings.title
              )}
              topLinks={getTopLinks(campaigns[campaignIndex].id)}
            />
          </div>
        </div>
      );
    }
  }

  function choosePage() {
    if (pageNum === 1) {
      return (
        <MonthlyPage1Crediflex
          campaign={campaigns}
          monthlyDate={monthlyDate}
          companyName={companyName}
          previewHtml={previewHtml}
          previewRefPage1={previewRefPage1}
        />
      );
    } else if (pageNum > 1) {
      return (
        <MonthlyPage2Crediflex
          campaign={campaigns[pageNum - 2]}
          report={getReportWithCampaign(campaigns[pageNum - 2].id)}
          lastCampaign={getSavedCampaignWithCampaign(
            campaigns[pageNum - 2].settings.title
          )}
          lastReport={getSavedReportWithCampaign(
            campaigns[pageNum - 2].settings.title
          )}
          topLinks={getTopLinks(campaigns[pageNum - 2].id)}
        />
      );
    }
    return null;
  }

  function showPages() {
    return (
      <>
        <div className="monthly-report" id="monthly-report">
          <div
            className={`monthly-page-${pageNum}-crediflex`}
            id={`monthly-page-${pageNum === 1 ? "1" : "2"}`}
          >
            {choosePage()}
            {pageNum !== 0 && pageNum !== 1 ? (
              <button
                className="btn-general btn-prev"
                onClick={() => prevClicked()}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="2rem"
                  viewBox="0 0 320 512"
                >
                  <path d="M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 246.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" />
                </svg>
              </button>
            ) : null}
            {pageNum !== campaigns.length + 1 ? (
              <button
                className="btn-general btn-next"
                onClick={() => nextClicked()}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="2rem"
                  viewBox="0 0 320 512"
                >
                  <path d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z" />
                </svg>
              </button>
            ) : null}
            <button
              className="btn-general btn-export"
              onClick={() => {
                setPageNum(0);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="2rem"
                viewBox="0 0 512 512"
              >
                <path d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V274.7l-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7V32zM64 352c-35.3 0-64 28.7-64 64v32c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V416c0-35.3-28.7-64-64-64H346.5l-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352H64zm368 56a24 24 0 1 1 0 48 24 24 0 1 1 0-48z" />
              </svg>
            </button>
          </div>
        </div>
      </>
    );
  }

  function main() {
    if (codeRun === 0 && links) {
      combineClicksOfSameLinks(links);
    }
    console.log(
      campaigns,
      reports,
      previewHtml.length,
      savedCampaigns,
      savedReports,
      links,
      topLinks,
      monthlyDate
    );
    if (
      campaigns &&
      reports &&
      previewHtml &&
      savedCampaigns &&
      savedReports &&
      links &&
      topLinks &&
      monthlyDate
    ) {
      if (pageNum === 0) {
        return (
          <>
            <div className="monthly-page-0" id="monthly-page-0">
              <button
                className="btn-general btn-next"
                onClick={() => nextClicked()}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="3em"
                  viewBox="0 0 320 512"
                >
                  <path d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z" />
                </svg>
              </button>
              <button
                className="btn-general btn-export"
                onClick={() => {
                  setPageNum(0);
                  // HelperFunctions.generatePDF();
                  HelperFunctions.generateImages(companyName);
                  HelperFunctions.generateIframe(companyName, previewHtml);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="2em"
                  viewBox="0 0 512 512"
                >
                  <path d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V274.7l-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7V32zM64 352c-35.3 0-64 28.7-64 64v32c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V416c0-35.3-28.7-64-64-64H346.5l-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352H64zm368 56a24 24 0 1 1 0 48 24 24 0 1 1 0-48z" />
                </svg>
              </button>
              <div className="monthly-pages-crediflex" id="monthly-pages">
                <div className="monthly-report" id="monthly-report">
                  <div
                    className="monthly-page-1-crediflex component-to-capture"
                    id="monthly-page-1"
                  >
                    <MonthlyPage1Crediflex
                      campaign={campaigns}
                      monthlyDate={monthlyDate}
                      companyName={companyName}
                      previewHtml={previewHtml}
                      previewRefPage1={previewRefPage1}
                      phoneImageRef={phoneImageRef}
                    />
                  </div>
                </div>
                {campaigns.map((campaign, index) => {
                  return showPagesInPrintView(index);
                })}
              </div>
            </div>
          </>
        );
      } else if (pageNum > 0) {
        return showPages();
      } else {
        return (
          <>
            <div className="monthly-report" id="monthly-report">
              Loading...
            </div>
          </>
        );
      }
    } else {
      return (
        <>
          <div className="monthly-report" id="monthly-report">
            Loading...
          </div>
        </>
      );
    }
  }

  return (
    <>
      <button
        className="btn-general btn-back"
        onClick={() => {
          backClicked();
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="2rem"
          viewBox="0 0 448 512"
        >
          <path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" />
        </svg>
      </button>
      <button
        className="btn-general btn-update"
        onClick={() => {
          updateData();
        }}
      >
        {isUpdateClicked ? "Updating..." : "Update"}
      </button>
      {main()}
    </>
  );
}
